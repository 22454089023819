import * as React from 'react';

import Category from '../../Category';
import ClipSection from '../../Clips';
import {LookbookStyled} from './styles';
import SliderComponent from '../../Banner/slidershow';
import {generateImageUrl} from '../../../utils/productHelper';
import {
  fetchBanners,
  fetchLookbookInfo,
} from '../../../API/makers/adminBanners';

const LookBook = () => {
  const tagBanner = 'lookbook-banner';
  const tagBackground = 'lookbook-background';
  const {banners: banner} = fetchBanners(tagBanner);
  const {banners: background} = fetchBanners(tagBackground);
  const {look_books} = fetchLookbookInfo();

  const filterLookBook = key => {
    const infoLB = look_books.find(item => item.tag === key);
    return infoLB;
  };

  const findCarousel = () => {
    const imgLB = look_books.map(item =>
      item.sliders.map(item => item.image_url)
    );
    return imgLB.flat();
  };

  return (
    <LookbookStyled
      background={generateImageUrl(background && background[0]?.image_url)}
    >
      {banner && <SliderComponent tagName={tagBanner} arrow="false" />}
      {filterLookBook('Top') && (
        <ClipSection
          title=""
          text1={filterLookBook('Top')?.line_one}
          text2={filterLookBook('Top')?.line_two}
          text3={filterLookBook('Top')?.line_three}
          clipTag="right"
          video={generateImageUrl(filterLookBook('Top')?.video_url)}
        />
      )}
      <Category category={findCarousel()} />
      {filterLookBook('Center') && (
        <ClipSection
          title=""
          text1={filterLookBook('Center')?.line_one}
          text2={filterLookBook('Center')?.line_two}
          text3={filterLookBook('Center')?.line_three}
          clipTag="left"
          video={generateImageUrl(filterLookBook('Center')?.video_url)}
        />
      )}
      {background && <div className="lookbook-banner-2" />}
      {filterLookBook('Bottom') && (
        <ClipSection
          text1={filterLookBook('Bottom')?.line_one}
          text2={filterLookBook('Bottom')?.line_two}
          text3={filterLookBook('Bottom')?.line_three}
          clipTag="right"
          video={generateImageUrl(filterLookBook('Bottom')?.video_url)}
        />
      )}
    </LookbookStyled>
  );
};

export default LookBook;
